.bolo__containerSlides {
    border-radius: 1rem;
}

.bullets {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: .5rem;

    margin: 0 0 1rem 0;

    .bolo__containerDots {
        background: var(--rosa-escuro);

        width: .875rem;
        height: .875rem;

        border: none;
        border-radius: 100px;

        margin: 0;
        padding: 0;
    }
}