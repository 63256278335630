.bolosList {
    background-color: var(--gelo);
    
    padding: 4rem 0;

    .bolosList__colQuadro {
        a {
            height: 100%;
        }
        a:hover {
            opacity: 1 !important;
        }
    }
}