.avisoCookie {
    width: 100%;
    height: auto;

    padding: 1rem 0;

    background-color: var(--bg-aviso-cookies);

    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 12;

    p {
        font-size: 1rem;
        font-weight: 400;
        color: var(--preto);

        margin: 0;

        a {
            display: inline;

            font-size: 1rem;
            font-weight: 900;
            color: var(--preto);
            text-decoration: underline;
        }
    }

    button {
        font-size: 1rem;
        font-weight: 900;
        color: var(--preto);

        margin: 0;
        padding: .5rem 3rem;

        border-radius: .5rem;
        border: none;

        background-color: var(--off-white);
    }
}