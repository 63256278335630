.header {
    width: 100%;

    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;

    box-shadow: 0 2px 5px 0px var(--sombra);
    -webkit-box-shadow: 0 2px 5px 0px var(--sombra);
    -moz-box-shadow: 0 2px 5px 0px var(--sombra);

    .header__logo {
        width: 100%;

        background: var(--bg-menu-logo);
        backdrop-filter: blur(1rem);

        padding: 1rem 0;

        img {
            height: 3rem;
            width: auto;
    
        }
    }

    .header__itensMenu {
        width: 100%;
        height: 3rem;

        background: var(--bg-menu-itens);
        backdrop-filter: blur(1rem);

        display: flex;
        justify-content: center;
        align-items: center;

        padding: .5rem 0;

        ul {
            padding: 0;
            margin: 0;

            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 1rem;

            li {
                list-style: none !important;

                a {
                    font-size: .875rem;
                    font-weight: 600;
                    color: var(--gelo);
                    text-transform: uppercase;

                    margin: 0;
                }

            }
        }
    }

}