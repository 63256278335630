.quadroLink {
    width: 100%;
    height: 100%;
    // min-height: 30vh;
    min-height: 18rem;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 1rem;

    h3 {
        font-size: 2rem !important;
    }

    // @media (min-height: 1200px) {
    //     min-height: 20vh;
    // }

    @media (max-width: 992px) {
        h3 {
            font-size: 1.5rem !important;
        }
    }
}
.bgRosaClaro {background: var(--gradient-bg-rosa-claro);}
.bgRosaEscuro {background: var(--gradient-bg-rosa-escuro);}
.bgVerde {background: var(--gradient-bg-verde);}