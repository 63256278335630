@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
    --preto: #333333;
    --branco:#FFFFFF;
    
    --gelo: #FAFAFA;
    --off-white: #FFF4E2;
    --bege: #ECCBBA;
    --marrom: #E6A68A;
    --rosa-claro: #EABEBB;
    --rosa-escuro: #DA9695;
    --verde: #CDB59B;

    --gradient-barra: linear-gradient(90deg, rgba(218,150,149,1) 0%, rgba(255,244,226,1) 40%, rgba(236,203,186,1) 60%, rgba(230,166,138,1) 80%, rgba(205,181,155,1) 100%);
    --gradient-bg-sobre-home: linear-gradient(180deg, rgba(255, 244, 226, 0.5) 0%, var(--gelo) 100%);
    --gradient-bg-rosa-claro: linear-gradient(135deg, var(--rosa-claro) 0%, rgba(230, 166, 138, 0.3) 100%);
    --gradient-bg-rosa-escuro: linear-gradient(135deg, var(--rosa-escuro) 0%, rgba(230, 166, 138, 0.3) 100%);
    --gradient-bg-verde: linear-gradient(135deg, var(--verde) 0%, rgba(230, 166, 138, 0.3) 100%);
    --gradient-bg-banner-interna: linear-gradient(135deg, var(--bege) 0%, var(--rosa-claro) 100%);

    --sombra: rgba(0, 0, 0, 0.1);
    --bg-menu-logo: rgba(218, 150, 149, 0.8);
    --bg-menu-itens: rgba(234, 190, 187, 0.8);
    --bg-quadro-home: rgba(250, 250, 250, 0.7);
    --bg-modal: rgba(205, 181, 155, 0.6);
    --bg-aviso-cookies: rgba(205, 181, 155, 0.9);
}

body, html {
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    background-color: var(--gelo);
}
a, button {
    text-decoration: none;
    cursor: pointer;
    transition: 1s;
}
a, button {
    display: flex;
    justify-content: center;
    align-items: center;
}
a:hover, button:hover {opacity: .7;}
input:focus, input:active, select:focus, select:active {
    outline: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    color: red;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-clear-button {
    color: red;
}

body::-webkit-scrollbar {width: 8px;}
body::-webkit-scrollbar-track {background: var(--rosa-escuro);}
body::-webkit-scrollbar-thumb {
    background-color: var(--rosa-claro);
    border-radius: 20px;
    border: 2px solid var(--rosa-escuro);
}

.text-rosa {
    color: var(--rosa-escuro);
}
.text-preto {
    color: var(--preto);
}
.text-branco {
    color: var(--gelo);
}


@media (max-width: 1400px) {}
@media (max-width: 1200px) {}
@media (max-width: 992px) {
    body, html {font-size: 15px;}
}
@media (max-width: 768px) {}
@media (max-width: 576px) {
    body, html {font-size: 14px;}
}