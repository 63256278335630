.bolo {
    background-color: var(--branco);

    width: 100%;
    min-height: 95vh;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10rem 0 4rem 0;

    @media (max-width: 576px) {
        margin-top: 3rem;
    }
}