.titleStyle {
    font-size: 2.5rem;
    font-weight: 900;

    img {
        height: 2rem;

        margin-left: 1rem;
    }

    @media (max-width: 576px) {
        font-size: 2rem;

        img {
            height: 1.5rem;
        }
    }
}