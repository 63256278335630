.btnWppStyle {
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    z-index: 11;

    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 10000px;
    border: 1px solid var(--gelo);

    background-color: var(--rosa-escuro);

    padding: 1rem;
    
    svg {
        width: 1.5rem;
        height: auto;

        color: var(--gelo);
    }
}