.modalStyle {
    width: 100%;
    height: 100vh;

    display: flex;
    justify-content: center;
    align-items: center;

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 20;

    background-color: var(--bg-modal);

    .modalStyle__quadroModal {
        width: 30%;
        height: auto;

        position: relative;

        background-color: var(--rosa-claro);

        border: 1px solid var(--rosa-escuro);
        border-radius: 1rem;

        padding: 4rem 2rem;

        button {
            position: absolute;
            top: .5rem;
            right: .5rem;

            border: none;

            background: transparent;

            font-size: 1rem;
            font-weight: 900;
            color: var(--gelo);
        }

        p {
            font-size: 1rem;
            font-weight: 600;
            color: var(--gelo);

            margin: 0;
        }

        @media (max-width: 1200px) {
            width: 50%;
        }
        @media (max-width: 992px) {
            width: 70%;
        }
        @media (max-width: 576px) {
            width: 90%;
        }
    }
}