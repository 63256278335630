.home {
    padding: 4rem 0;

    background: var(--gradient-bg-sobre-home);
    
    iframe {
        width: 100%;
        height: 100%;
        min-height: 35dvh;
        
        border-radius: 1rem;
        
        @media (min-width: 769px) and (max-width: 992px) {
            max-height: 50vh;
        }
        @media (max-width: 768px) {
            min-height: 50vh;
        }
    }

    .home__mCustom {
        margin-top: 8rem !important;
    }
}
