.footer {
    background: url('../../assets/bg-rodape.png');
    background-position: 60% 25%;
    background-repeat: no-repeat;
    background-size: 250%;

    padding: 1rem 0;

    img {
        width: 6rem;
        
        margin-bottom: .5rem;
    }

    p {
        font-size: .75rem;
        font-weight: 700;
        color: var(--branco);

        margin: 0;
    }

    ul {
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        @media (max-width: 575px) {
            flex-direction: column;
            gap: .5rem;
        }

        li {
            list-style: none !important;

            a {
                font-size: .875rem;
                font-weight: 600;
                color: var(--gelo);
                text-transform: uppercase;

                margin: 0;
            }

        }
    }
}