.bolo__quadro {
    background-color: var(--gelo);

    width: 100%;
    max-height: fit-content;

    padding: 2rem;

    border-radius: 1rem;

    box-shadow: 0 0 2rem 0 var(--sombra);
    -webkit-box-shadow: 0 0 2rem 0 var(--sombra);
    -moz-box-shadow: 0 0 2rem 0 var(--sombra);

    p {
        font-size: 1rem;
        font-weight: 400;
        color: var(--preto);

        margin: 0 0 .5rem 0;

        span {
            font-weight: 700;
        }
    }

    h1 {
        font-size: 2.5rem;
        font-weight: 900;
        color: var(--rosa-escuro);

        margin: 0 0 1rem 0;
    }

    a {
        font-size: 1rem;
        font-weight: 900;
        color: var(--gelo);

        margin: 0;
        padding: 1rem 3rem;

        border-radius: .5rem;

        background-color: var(--rosa-escuro);
    }

    @media (max-width: 768px) {
        h1 {
            font-size: 2rem;
        }
    }

    @media (max-width: 576px) {
        h1 {
            font-size: 1.875rem;
        }

        a {
            width: 100%;
        }
    }
}