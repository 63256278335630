.termoUso {
    margin-top: 8rem;

    padding: 4rem 0;

    h2 {
        font-size: 2rem;
        font-weight: 600;
        color: var(--rosa-escuro);

        margin: 0 0 .5rem 0;
    }
    p {
        font-size: 1rem;
        font-weight: 400;
        color: var(--preto);

        margin: 0;
    }
}