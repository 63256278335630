.descOrcamento {
    padding: 8rem 0 2rem 0;

    p {
        font-size: 1rem;
        font-weight: 400;
        text-align: center;
        color: var(--verde);
    }
}
.formOrcamento {
    padding: 2rem 0 8rem 0;

    input, select {
        border: 2px solid var(--verde);
        border-radius: .5rem;

        padding: .5rem 1rem;

        color: var(--preto);
    }
    input::placeholder {
        color: var(--preto);
    }
    select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;

        background: none;

        cursor: pointer;
    }
    button {
        min-width: 10rem;

        font-size: 1rem;
        font-weight: 900;
        color: var(--gelo);

        margin: 0;
        padding: .5rem 2rem;

        border: none;
        border-radius: .5rem;

        background-color: var(--verde);
    }
}