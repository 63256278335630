.erro404 {
    height: 96vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    background-color: var(--bege-claro);

    h1 {
        font-size: 3rem;
        font-weight: 900;
        color: var(--verde);
        text-align: center;

        margin: 0 0 2rem 0;
    }
    button {
        font-size: 1rem;
        font-weight: 900;
        color: var(--gelo);

        margin: 0;
        padding: 1rem 3rem;

        border-radius: .5rem;
        border: none;

        background-color: var(--rosa-escuro);
    }

    @media (max-width: 576px) {
        height: 100vh;

        h1 {
            font-size: 2rem;
        }
    }
}