.quadro {
    background-color: var(--off-white);

    border-radius: 1rem;
    border: 1px solid var(--rosa-escuro);

    height: 100%;

    .quadro__imgContainer{
        height: 30rem;

        img {
            height: 100%;

            object-fit: cover;
            object-position: center 30%;

            border-radius: 1rem 1rem 0 0;
        }
    }

    .quadro__tituloContainer {
        width: 100%;
        height: calc(100% - 30rem);

        padding: 1.5rem;

        display: flex;
        justify-content: center;
        align-items: center;

        border-top: 1px solid var(--rosa-escuro);
        
        h2 {
            font-size: 1.5rem;
            font-weight: 700;
            color: var(--rosa-escuro);
            text-align: center;
    
            margin: 0;
        }
    }

}