.bannerHome {
        width: 100%;
        height: 50vh;

        position: relative;
        z-index: 11;
    video {
        width: 100%;
        height: 50vh;

        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        z-index: 1;

        object-fit: cover;
    }
}

.bannerInterna {
    width: 100%;
    height: 100%;
    min-height: 40vh;

    background: var(--gradient-bg-banner-interna);

    position: relative;
    z-index: 11;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 4rem 0;

    .bannerInterna__containerStyle {
        position: relative;
        z-index: 2;
    }

    img {
        height: 90%;

        position: absolute;
        z-index: 1;
        left: 0;
        bottom: 0;

        @media (max-width: 1200px) {
            opacity: .5;
        }

        @media (max-width: 576px) {
            opacity: .2;
        }
    }
}


.bannerPadrao {
    background: url('../../assets/bg-banner.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    min-height: 30vh;

    padding: 5rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;
    z-index: 11;

    .bannerPadrao__filtro {
        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;
        left: 0;
        z-index: 11;

        background-color: var(--bege-claro);
        opacity: .4;
    }

    .bannerPadrao__containerCustom {
        position: relative;
        z-index: 12;
    }

    h1 {
        font-size: 2rem;
        font-weight: 900;
        color: var(--branco);

        text-align: center;
    }

    img {
        max-height: 3rem;
        width: auto;
    }
}